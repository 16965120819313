.panel-body {
  margin-left: 0px;
}

.panel {
  display: block;
  position: relative;
  position: relative;
  width: 100% !important;
  margin: 10px 10px !important;
  border: 1px solid #ddd !important;
}
