.flexcontaner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center !important;
  opacity: 0.5;
}

.card-div {
  display: flex;
  flex-direction: row;
}

.cardsmall {
  height: 4rem;
  width: 6rem;
  background-color: whitesmoke;
  border: none;
  border-radius: 5px;
  margin: 3px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}

.cardflexcolum {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardflexcolum > p {
  margin: 0px;
}
.cardflexcolum > hr {
  margin: 2px;
}

@media screen and (max-width: 680px) {
  .button_class {
    display: none  !important;
  }
}

.cardpara {
  margin-bottom: 5px !important;
}
